import { InMemoryCache } from '@apollo/client/core';

import { offsetStylePagination, pageStylePagination, timestampStylePagination } from '@/modules/mergeQueries';

const isServer = typeof window === 'undefined';
const windowApolloState = !isServer && (window.__NEXT_DATA__ as any)?.apolloState;

export const createCache = (): InMemoryCache => {
  const cache = new InMemoryCache({
    resultCaching: true,
    typePolicies: {
      Query: {
        fields: {
          // Booking,
          listClubEventsForAdmin: timestampStylePagination(['clubId', 'filter', ['fromDate', 'toDate']]),
          listClubEventsForMe: timestampStylePagination(['clubId', 'filter', ['fromDate', 'toDate']]),
          listNextClubEventsForAdmin: timestampStylePagination(['clubId']),
          listNextClubEventsForMe: timestampStylePagination(['clubId']),
          listReservationsByScheduled: timestampStylePagination(['scheduledEventId']),
          listEventMemberAttendances: offsetStylePagination(['scheduledEventId']),
          listClassMembers: offsetStylePagination(['classId', 'role']),
          listEventsForMe: timestampStylePagination([
            'search',
            ['clubIds', 'teamIds', 'rsvpStatus', 'tab', 'lastTimestamp'],
          ]),
          listEventsForMeBetween: timestampStylePagination([
            'search',
            ['clubIds', 'teamIds', 'rsvpStatus', 'tab'],
            'filter',
            ['fromDate', 'toDate'],
          ]),
          // Club
          listClubs: offsetStylePagination(['ids']),
          listClubsWithNewUpdate: offsetStylePagination(['filter', ['limit']]),
          listMyClubs: offsetStylePagination(),
          listAdminClubsWithMeta: offsetStylePagination(['status', 'filter', ['limit']]),
          listAdminClubs: offsetStylePagination(['status', 'filter', ['limit']]),
          listConnectedClubs: offsetStylePagination(),
          listClubMembers: offsetStylePagination([
            'clubId',
            'filter',
            ['sortBy'],
            'search',
            ['name', 'role', 'customFields', 'team'],
          ]),
          listClubMembersAdmin: offsetStylePagination([
            'clubId',
            'filter',
            ['sortBy'],
            'search',
            ['name', 'role', 'customFields', 'team'],
          ]),
          listClubTeams: offsetStylePagination(['clubId', 'filter', ['limit']]),
          listMyTeams: offsetStylePagination(['clubId']),
          listTeamMembers: offsetStylePagination(['teamId']),
          listTeamMembersByEnrollment: offsetStylePagination(['teamEnrollmentId', 'search', ['teamId']]),
          listClubAttachmentsForAdmin: offsetStylePagination(['clubId', 'filter', ['limit']]),
          listClubAttachments: offsetStylePagination(['clubId', 'filter', ['limit']]),
          listTeamEnrollments: offsetStylePagination(['clubId']),
          listTeamsWithNewUpdate: offsetStylePagination(['clubId']),
          // Message
          listMyContacts: offsetStylePagination(),
          listLatestChannels: offsetStylePagination(),
          listMessages: timestampStylePagination(['chatChannelId']),
          // Notification
          listMyNotifications: timestampStylePagination(),
          // Post
          findFeeds: pageStylePagination(['search', ['q']]),
          listPostsPublic: timestampStylePagination([]),
          listPostsByClub: timestampStylePagination(['clubId']),
          listPostsByClubForAdmin: offsetStylePagination(['clubId']),
          listPostsByUser: timestampStylePagination(['userId']),
          listPostsByMe: timestampStylePagination(),
          listPostsByFeed: timestampStylePagination([]),
          listPostsByMyBookmark: timestampStylePagination(),
          listPostLikes: timestampStylePagination(['postId']),
          listPostComments: timestampStylePagination(['postId']),
          listPostShares: timestampStylePagination(['userId']),
          listClubFeed: timestampStylePagination(['clubId']),
          // User
          listInvitationsByClub: timestampStylePagination(['clubId']),
          listInvitationsByTeam: timestampStylePagination(['teamId']),
          listSingleInvitationsByClub: timestampStylePagination(['clubId']),
          listBulkInvitationsByClub: timestampStylePagination(['clubId']),
          listInvitationsByMe: timestampStylePagination(),
          listUsersPublic: offsetStylePagination(),
          // Product
          listProductsByClub: offsetStylePagination(['clubId']),
          listPublicProductsByClub: offsetStylePagination(['clubId', 'params', ['category']]),
          // Product
          listOrderDetailsByClub: offsetStylePagination(['clubId']),
          // Sponsor Promotion
          listSponsorPromotions: offsetStylePagination(['sponsorId']),
          listClubSponsorsForClub: offsetStylePagination(['clubId', 'filter', ['sortBy']]),
          listClubSponsors: offsetStylePagination(['clubId', 'filter', ['sortBy', 'limit']]),
          // Settings
          listClubLinks: offsetStylePagination(['clubId', 'filter', ['limit']]),
        },
      },
      Logo: {
        keyFields: false,
      },
      Urls: {
        keyFields: false,
      },
      User: {
        keyFields: false,
      },
      Count: {
        keyFields: false,
      },
    },
  });

  if (!isServer) {
    cache.restore(windowApolloState || {});
  }

  return cache;
};
